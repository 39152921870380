import { Environment } from '../models/environment';

export const environment: Environment = {
  environment: 'preprod',
  production: true,
  api: {
    movinmotionBackend: 'https://back.app.preprod.movinmotion.net',
    movinmotionService: 'https://services.preprod.movinmotion.net',
  },
  appEndpoints: {
    talents: '/api/talent/v1',
    core: '/api/core/v1',
    filmfrance: 'api/filmfrance/v1',
    nda: '/api/nda/v1',
    movinmotion: '/api/movinmotion/v1',
  },
  authentication: {
    url: 'https://accounts.app.preprod.movinmotion.net',
    queryParamKeys: {
      token: 'mmt',
      entropy: 'mment',
      redirectUrl: 'mmr',
      resetPassword: 'mmrp',
      changePassword: 'mmcp',
      email: 'mme',
      movinmotionAuthError: 'mmae',
      language: 'mml',
    },
  },
  apps: {
    core: 'https://entreprise.app.preprod.movinmotion.net',
    worker: 'https://salarie.app.preprod.movinmotion.net',
    compta: 'https://compta.app.preprod.movinmotion.net',
    talents: 'https://talents.app.preprod.movinmotion.net',
    insurance: 'https://assurance.app.preprod.movinmotion.net',
    socialPlay: 'https://app.preprod.movinmotion.net',
    social: 'https://social.app.preprod.movinmotion.net',
    admin: 'https://admin.app.preprod.movinmotion.net',
    movinmotion: 'https://www.movinmotion.com',
  },
  thirdParty: {
    siren: {
      endpoint: 'https://api.insee.fr/api-sirene/3.11',
      token: '30bb2afa-df6f-4447-bb2a-fadf6f144710',
    },
    googlePlaces: 'AIzaSyAlH-SI72VwZIWz6AG9deLrYMkKmP_8FYk',
    yousign: {
      sandbox: true,
    },
    mixpanel: {
      token: 'bdbd19d222ad0d7d44c8c10cb8ddd8dc',
      name: 'MM preprod',
    },
    axeptio: {
      clientId: '651a7f257f3d8d2223d1f106',
      cookiesVersion: 'movinmotion - application - preprod-fr',
    },
  },
  bucket: {
    avatars: 'avatars.storage.preprod.movinmotion.net',
  },
  cookieDomain: '.app.preprod.movinmotion.net',
};
